<template>
  <div class="ui-parent-section">
    <UISection>
      <template #title>
        <div>Колапсы</div>
      </template>

      <div>
        <el-collapse v-model="activeNames">
          <el-collapse-item title="Заголовок секции" name="1">
            <h3>Consistent with real life</h3>
            <p>
              Consistent within interface: all elements should be consistent,
              such as: design style, icons and texts, position of elements, etc.
            </p>
          </el-collapse-item>
          <el-collapse-item title="Заголовок секции" name="2">
            <h3>Operation feedback</h3>
            <p>
              Visual feedback: reflect current state by updating or rearranging
              elements of the page.
            </p>
          </el-collapse-item>
          <el-collapse-item title="Заголовок секции" name="3">
            <h3>Simplify the process</h3>
            <p>
              Definite and clear: enunciate your intentions clearly so that the
              users can quickly understand and make decisions;
            </p>
            <p>
              Easy to identify: the interface should be straightforward, which
              helps the users to identify and frees them from memorizing and
              recalling.
            </p>
          </el-collapse-item>
          <el-collapse-item title="Заголовок секции" name="4">
            <h3>Decision making</h3>
            <p>
              Controlled consequences: users should be granted the freedom to
              operate, including canceling, aborting or terminating current
              operation.
            </p>
          </el-collapse-item>
        </el-collapse>
      </div>
    </UISection>
  </div>
</template>

<script>
import UISection from '@/views/ui/components/UISection'
export default {
  name: 'Collapse',
  components: { UISection },
  data() {
    return {
      activeNames: ['1'],
    }
  },
}
</script>

<style lang="sass">
.color-box
  width: 180px
  background: #FFFFFF
  border: 1px solid #DCDCDC
  padding: 8px
  border-radius: 8px

  &__wrap
    display: flex
    justify-content: flex-start
    align-items: flex-start
    flex-wrap: wrap
    gap: 10px

  &__color
    width: 100%
    padding-top: 100%
    background: darkred
    border-radius: 8px
    margin-bottom: 8px
    border: 1px solid #DCDCDC
</style>
