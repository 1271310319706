var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "ui-parent-section" },
    [
      _c(
        "UISection",
        {
          scopedSlots: _vm._u([
            {
              key: "title",
              fn: function () {
                return [_c("div", [_vm._v("Колапсы")])]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c(
            "div",
            [
              _c(
                "el-collapse",
                {
                  model: {
                    value: _vm.activeNames,
                    callback: function ($$v) {
                      _vm.activeNames = $$v
                    },
                    expression: "activeNames",
                  },
                },
                [
                  _c(
                    "el-collapse-item",
                    { attrs: { title: "Заголовок секции", name: "1" } },
                    [
                      _c("h3", [_vm._v("Consistent with real life")]),
                      _c("p", [
                        _vm._v(
                          " Consistent within interface: all elements should be consistent, such as: design style, icons and texts, position of elements, etc. "
                        ),
                      ]),
                    ]
                  ),
                  _c(
                    "el-collapse-item",
                    { attrs: { title: "Заголовок секции", name: "2" } },
                    [
                      _c("h3", [_vm._v("Operation feedback")]),
                      _c("p", [
                        _vm._v(
                          " Visual feedback: reflect current state by updating or rearranging elements of the page. "
                        ),
                      ]),
                    ]
                  ),
                  _c(
                    "el-collapse-item",
                    { attrs: { title: "Заголовок секции", name: "3" } },
                    [
                      _c("h3", [_vm._v("Simplify the process")]),
                      _c("p", [
                        _vm._v(
                          " Definite and clear: enunciate your intentions clearly so that the users can quickly understand and make decisions; "
                        ),
                      ]),
                      _c("p", [
                        _vm._v(
                          " Easy to identify: the interface should be straightforward, which helps the users to identify and frees them from memorizing and recalling. "
                        ),
                      ]),
                    ]
                  ),
                  _c(
                    "el-collapse-item",
                    { attrs: { title: "Заголовок секции", name: "4" } },
                    [
                      _c("h3", [_vm._v("Decision making")]),
                      _c("p", [
                        _vm._v(
                          " Controlled consequences: users should be granted the freedom to operate, including canceling, aborting or terminating current operation. "
                        ),
                      ]),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }